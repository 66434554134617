import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"

import "../components/assets/css/bootstrap.min.css"
import "../components/assets/css/style.css"
import "../components/assets/css/hover-effects.css"
import "./layout2.css"

import MainMenu from "./Menu/MainMenu"

export default function Layout2({ children }) {
    return <StaticQuery
        query={graphql`
      query {
        allWpMenu {
    edges {
      node {
        id
        slug
        menuItems {
          nodes {
            url
            label
            id
          }
        }
      }
    }
  }
  }
    `}
        render={data => {
            console.log(data)
            return (
                
                <section className="layout2">
                <Helmet>
                     <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js" /> 
                     <script src={withPrefix('script.js')} type="text/javascript" />
                  </Helmet>
                    <div className="container">
                        <div className="row">
                            <div className="menu col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-3">
                                <MainMenu menu={data} />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-8">

                                {children}
                            </div>
                        </div>
                    </div>
                </section>
            )
        }}
    />
}
