import React, { Component } from "react"

import "../assets/css/bootstrap.min.css"
import "../assets/css/style.css"
import "../assets/css/hover-effects.css"
import "../layout2"

import logo1 from "../assets/images/back.png"
import logo2 from "../assets/images/second.png"
import logo3 from "../assets/images/top.png"



class MainMenu extends Component {
  render() {

    const data = this.props.menu.allWpMenu.edges[0].node.menuItems.nodes;
    // .data.wpMenu.menuItems.nodes;
    // const data = this.props.menu.allWordpressWpApiMenusMenusItems.edges[0].node.items
    console.log(data)

    return (
      <div>
        <div className="logo_container" style={{marginTop: '6px' }} >
          <div className="logo_n">
            <div className="logo_layer_back">
              <img src={logo1} alt="" />
            </div>
            <div className="logo_layer_second">
              <img src={logo2} alt="" />
            </div>

            <div className="logo_layer_top">
	       <a href="/" rel="noreferrer" target="_blank">
              <img src={logo3} alt="" />
	       </a>
            </div>
          </div>
        </div>
        <ul>
          {/* <li><img src={logo} alt="Logo" className="centerImg" /></li> */}

          <nav id="hamnav">
            <label htmlFor="hamburger">&#9776;</label>
            <input type="checkbox" id="hamburger" />
            <div id="hamitems">
              {data.map((item) =>
                <li key={item.id}>
                  <a href={item.url}>{item.label}</a>
                </li>

              )}
            </div>
          </nav>

        </ul>
      </div>
    )
  }
}

export default MainMenu
// class MainMenu extends Component {
//     render() {

//         const data = this.props.menu.allWpMenu.edges[0].node.menuItems.nodes;
//         // .data.wpMenu.menuItems.nodes;
//         // const data = this.props.menu.allWordpressWpApiMenusMenusItems.edges[0].node.items
//         console.log(data)

//         return (
//             <div>
//                 <ul>
//                     <li><img src={logo} alt="Logo" className="centerImg"></li>
//                         <nav id="hamnav">
//                             <label for="hamburger">&#9776;</label>
//                             <input type="checkbox" id="hamburger" />

//                             <div id="hamitems">
//                                 {data.map((item) =>
//                                     <li key={item.id}>
//                                         <a href={item.url}>{item.label </a>
//                                     </li>

//                                 )}
//                             </div>
//                         </nav>
//                 </ul>
//             </div>
//         )
//     }
// }

// export default MainMenu
