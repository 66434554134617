import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"
import Layout2 from "../components/layout2"
import Img from "gatsby-image"

import "../components/assets/css/bootstrap.min.css"
import "../components/assets/css/style.css"
import "../components/assets/css/hover-effects.css"
import "../components/layout2"

class PageTemplate extends Component {
    render() {
        console.log(this.props.data);
        const page = this.props.data.wpPage;
        let resolutions = null;
        if(page.featuredImage){
           resolutions = page.featuredImage.node.localFile.childImageSharp.original;
           resolutions.width = 300;
           resolutions.height = 300;
        }

        // STEP #5: Use title and content in Gatsby.
        return (
            <Layout2>
            <Helmet>
               <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js" /> 
               <script src={withPrefix('script.js')} type="text/javascript" />
            </Helmet>
                <h1 dangerouslySetInnerHTML={{ __html: page.title }} />
                {resolutions && <div><Img resolutions={resolutions} /></div>}
                <div dangerouslySetInnerHTML={{ __html: page.content }} />
                {/* <p dangerouslySetInnerHTML={{__html: page.slug}} /> */}
            </Layout2>
        );
    }
}

PageTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array
};

export default PageTemplate;

// STEP #4: Get current WP Post data via ID.
export const pageQuery = graphql`
    query($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            content
            featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      original {
                        src
                        height
                        width
                      }
                    }
                  }
                }
              }
        }
    }
`;